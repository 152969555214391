import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Carousel from '../components/carousel'
import EmailCaptureModal from '../components/email-capture-modal'
import Footer from '../components/footer'
import Header from '../components/header'
import SEO from '../components/seo'
import Shelf from '../components/shelf'
import remocoClient from '../remocoClient'
import { get, setGaScreenName } from '../utils'

async function getDiscoverCollectionGroup() {
    /* 
        Using expand 1 to just get the metadata for each collection group item.
        TODO: Use expand 3 when it supports returning videos for categories in a collection group.
        JIRA issue: CMS2-1096
    */
    const expandLevel = 1
    const limit = 500

    return await remocoClient.getCollection('discover', expandLevel, limit).catch(err => {
        console.error('Error retrieving collection group:', err)
        navigate('/404')
    })
}

async function getRelevantDiscoverData() {
    const collGroup = await getDiscoverCollectionGroup()
    const entries = get(['data', 'entries', 'list'], collGroup) || []

    return entries
}

function separateFeaturedShelf(discoverItems = []) {
    let featuredShelf = [],
        shelfData = []

    if (discoverItems.length > 0) {
        shelfData = discoverItems.slice() // make a copy to avoid mutating original
        const i = shelfData.findIndex(item => item.id === 'featured')

        if (i !== -1) {
            featuredShelf = shelfData.splice(i, 1)[0]
        }
    }

    return {
        featuredShelf,
        shelfData,
    }
}

function populateShelves(shelvesData) {
    return shelvesData.map((shelfData, i) => {
        return <Shelf id={shelfData.id} type={shelfData.type} key={`${shelfData.id}--${i}`} useEditorialFields />
    })
}

const DiscoverPage = ({ location }) => {
    const [discoverItems, setDiscoverItems] = useState([])
    const [featuredShelf, setFeaturedShelf] = useState({})
    const [shelfData, setShelfData] = useState([])

    useEffect(() => {
        setGaScreenName('Discover')
        getRelevantDiscoverData()
            .then(data => {
                setDiscoverItems(data)
            })
            .catch(err => {
                console.error('Error retrieving Discover page data:', err)
            })
    }, [])

    useEffect(() => {
        const shelvesData = separateFeaturedShelf(discoverItems)

        setFeaturedShelf(shelvesData.featuredShelf)
        setShelfData(shelvesData.shelfData)
    }, [discoverItems])

    return (
        <>
            <SEO title="Discover" />
            <EmailCaptureModal />
            <Header siteTitle="VideoElephant" currentPath={location.pathname} />
            {featuredShelf ? <Carousel data={featuredShelf} useEditorialFields /> : ''}
            <DiscoverLayout>{populateShelves(shelfData)}</DiscoverLayout>
            <Footer />
        </>
    )
}

const DiscoverLayout = styled.div`
    min-height: 100%;
    margin: 0 auto;
    padding-left: var(--discover-page-margin-desktop);
    padding-bottom: 60px;

    @media screen and (max-width: 672px) {
        padding-left: var(--discover-page-margin-mobile);
        padding-bottom: 48px;
    }
`

export default DiscoverPage
