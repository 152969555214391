import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import carouselStyles from './carousel.module.css'
import CustomImage from './custom-image'
import { getExpandedCollectionGroupItemData, getHref, getRelevantCollGroupData } from '../utils'

const SliderArrow = ({ children, className, onClick, style, customStyle }) => {
    return (
        <SlideArrow id="slide-arrow" className={className} onClick={onClick} style={{ ...style, ...customStyle }}>
            {children}
        </SlideArrow>
    )
}

const PrevArrow = props => {
    return (
        <SliderArrow {...props} className="prev-arrow">
            <img alt="show previous slide" src="/icon-carousel-left.svg" />
        </SliderArrow>
    )
}

const NextArrow = props => {
    return (
        <SliderArrow {...props} className="next-arrow">
            <img alt="show next slide" src="/icon-carousel-right.svg" />
        </SliderArrow>
    )
}

const getSlideInfo = (baseTitle, baseSynopsis, marketingSynopsis, marketingTitle, useEditorialFields = false) => {
    // override the base values with the marketing values if using editorial fields
    const title = useEditorialFields && marketingTitle && marketingTitle.length > 0 ? marketingTitle : baseTitle
    const synopsis =
        useEditorialFields && marketingSynopsis && marketingSynopsis.length > 0 ? marketingSynopsis : baseSynopsis

    return (
        <>
            <h2>{title}</h2>
            <p>{synopsis}</p>
        </>
    )
}

const getSlides = (slideData = [], useEditorialFields) => {
    return slideData.map(slide => {
        const { ctitle, description, id, image21x9, marketingSynopsis, marketingTitle } = slide

        return (
            <Slide key={id}>
                <Link to={getHref(slide)} state={slide}>
                    <Info>
                        {getSlideInfo(ctitle, description, marketingSynopsis, marketingTitle, useEditorialFields)}
                    </Info>
                    <CustomImage imgSrc={image21x9} alt={ctitle} isSlide />
                    <div className="gradient"></div>
                </Link>
            </Slide>
        )
    })
}

const Carousel = ({ data, useEditorialFields = false }) => {
    const [slidesData, setSlides] = useState([])
    const { id, type } = data

    useEffect(() => {
        getExpandedCollectionGroupItemData(id, type)
            .then(items => {
                getRelevantCollGroupData(items)
                    .then(relevantData => {
                        setSlides(relevantData ? relevantData.data : [])
                    })
                    .catch(err => {
                        console.error('Unable to retrieve relevant collection group item data:', err)
                    })
            })
            .catch(err => {
                console.error('Unable to retrieve expanded collection group item data:', err)
            })
    }, [id, type])

    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        className: 'carousel',
        infinite: true,
        pauseOnFocus: false,
        pauseOnHover: true,
        speed: 500,
        slidesToShow: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }

    return (
        <CarouselContainer className={carouselStyles.carouselWrapper}>
            {slidesData.length > 0 ? <Slider {...settings}>{getSlides(slidesData, useEditorialFields)}</Slider> : null}
        </CarouselContainer>
    )
}

const SlideArrow = styled.div`
    &#slide-arrow {
        position: absolute;
        top: calc(50% - 34px);
        width: 68px;
        height: 68px;
        background: rgb(20, 20, 20, 0.4);
        transition: all 0.3s ease-in-out;
        z-index: 2;

        &.prev-arrow {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            left: -34px;

            > img {
                right: 16px;
            }

            &:hover {
                cursor: pointer;
                transform: translateX(10px);
                background: rgba(20, 20, 20, 0.7);
                transition: all 0.3s ease-in-out;
            }
        }

        &.next-arrow {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            right: -34px;

            > img {
                left: 16px;
            }

            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
                background: rgba(20, 20, 20, 0.7);
                transition: all 0.3s ease-in-out;
            }
        }

        > img {
            position: absolute;
            margin: 0;
            height: 16px;
            width: 14px;
            top: calc(50% - 8px);
        }

        &:before {
            content: none !important;
        }
    }
    @media screen and (max-width: 672px) {
        &#slide-arrow {
            height: 40px;
            width: 40px;
            top: calc(50% - 20px);

            &.prev-arrow {
                left: -20px;

                > img {
                    right: 8px;
                }
            }

            &.next-arrow {
                right: -20px;

                > img {
                    left: 8px;
                }
            }

            > img {
                height: 12px;
                width: 9px;
                top: calc(50% - 6px);
            }
        }
    }
`

const CarouselContainer = styled.div`
    overflow-x: hidden;
    min-height: calc(90vw * 0.428571);

    .slick-slide {
        img {
            margin: 0;
            object-fit: cover;
            width: 100%;
        }

        .gradient {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 260px;
            margin: 0 var(--image-padding);
            background: rgb(19, 22, 27);
            background: linear-gradient(-180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.95) 100%);
        }
    }

    @media screen and (max-width: 672px) {
        padding-top: 48px;

        .slick-slide {
            .gradient {
                height: 108px;
            }
        }
    }
`

const Slide = styled.div`
    position: relative;
`

const Info = styled.div`
    position: absolute;
    bottom: 44px;
    left: 48px;
    z-index: 2;
    max-width: 623px;
    word-wrap: break-word;

    h2 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        font-size: 36px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 44px;
        margin-bottom: 16px;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        opacity: 0.7;
        font-size: 16px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 20px;
        height: 60px;
        margin: 0;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 672px) {
        max-width: 205px;
        bottom: 15px;
        left: 16px;

        h2 {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 20px;
            margin-bottom: 0;
        }
        p {
            display: none;
        }
    }
`

export default Carousel
